/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Link,
  Divider,
  SvgIcon,
  Dialog,
  Drawer,
  Toast
} from '../elements';
import { Swiper } from '../modules';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useIntersection } from '../../hooks/useIntersection';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';

import {
  Card,
  CardImageContainer,
  CardImage,
  CardIdBadge,
  CardIdText,
  CardInfoContainer,
  CardPriceInfoContainer,
  cardPagination,
  cardPaginationBullet,
  cardPaginationBulletActive,
  SwiperControlsContainer,
  ComingSoonContainer,
  ComingSoonContent,
  LeftArrow,
  RightArrow,
  ApartmentContentWrapper,
  ApartmentNeighbourhood,
  ApartmentStreet,
  ApartmentDetail,
  BestDealContainer,
  BestDealTitle,
  BestDealContent,
  BestDealPrice,
  HeaderDialogWrapper,
  OwnerButtons,
  ModalContainer,
  DialogButtonTitle,
  DialogButtonSubtitle,
  DialogGuestTitle,
  DialogGuestSubtitle
} from './PropertyCard.styles';
import { useToggle } from '../../hooks/useToggle';
import copyToClipboard from '../../utils/copyToClipboard';
import axios from 'axios';

const PropertyCard = ({
  property,
  mapVersion = false,
  divider,
  rounded,
  idBadgeLocation = 'top',
  withBody = true,
  seasonalLink = false,
  season = 'none',
  availableOnRequest,
  customPrice,
  customOriginalPrice,
  withReservationInvite = false,
  reservationInvite = {},
  casatb
}) => {
  const cardRef = useRef();
  const [swiper, setSwiper] = useState(null);
  const [inView, setInView] = useState(false);
  const [toastOpen, setToastOpen] = useState();
  const [guestDialog, setGuestDialog] = useState(true);
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const [openShareBookDialog, setOpenShareBookDialog] = useToggle();
  const mobile = ['xs', 'sm'].includes(useBreakpoint());
  useIntersection(cardRef, () => setInView(true));
  const swiperActive = !property.expected && property.photos?.length >= 5;
  const isWebview = window.isWebview;

  const slideTo = (e, fn) => {
    e.preventDefault();
    swiper && swiper[fn]();
  };

  if (toastOpen) {
    setTimeout(() => {
      setToastOpen(false);
    }, 5000);
  }

  const removeInvitation = async() => {
    const formData = new FormData();
    formData.append('reservation_invite[status]', 'Declined');
    const response = await axios.patch(reservationInvite?.update_path, formData);

    if (response.data.status !== 200) {
      setOpenShareBookDialog();
      setErrorToastOpen(true);
      return;
    }

    setOpenShareBookDialog();
    setGuestDialog(false);
    setTimeout(() => {
      window.location.href = `${window.location.origin}/member/reservations`;
    }, 5000);
  };

  const reservationInviteOwnershipContent = {
    owner: {
      cardBadgeContent:
        <Box>
          {t('member_reservation.reservation_invite.badge_label_owner')}
          <SvgIcon ml={0.5} name="share_booking_icon" />
        </Box>,
      modalHeaderContent:
        <HeaderDialogWrapper>
          <Typography component="h2" color="primary.light" variant="h6" fontWeight="500">
            {t('member_reservation.reservation_invite.dialog_subtitle')}
          </Typography>
          <Typography component="h1" color="primary.main" variant="h4">
            {t('member_reservation.reservation_invite.dialog_title_owner')}
          </Typography>
        </HeaderDialogWrapper>,
      modalBodyContent:
        <ModalContainer>
          <OwnerButtons
            href={`https://wa.me/?text=${t('invitations_page.body_message_line_1')}${t('invitations_page.body_message_line_2', { owner: reservationInvite?.owner_name })}${t('invitations_page.body_message_line_3')}${t('invitations_page.body_message_line_4', { share_link: reservationInvite?.reservation?.share_reservation_url })}`}
            my={0.5}
          >
            <SvgIcon mx={1} name="whatsapp" size='20' />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <DialogButtonTitle>
                {t('member_reservation.reservation_invite.whatsapp')}
              </DialogButtonTitle>
              <DialogButtonSubtitle>
                {t('member_reservation.reservation_invite.whatsapp_description')}
              </DialogButtonSubtitle>
            </Box>
          </OwnerButtons>
          <OwnerButtons
            href={`mailto:?to=&subject=${t('invitations_page.email_subject_message', { owner_name: reservationInvite.owner_name })}&body=${t('invitations_page.body_message_line_1')}${t('invitations_page.body_message_line_2', { owner: reservationInvite?.owner_name })}${t('invitations_page.body_message_line_3')}${t('invitations_page.body_message_line_4', { share_link: reservationInvite?.reservation?.share_reservation_url })}`}
            my={0.5}
          >
            <SvgIcon mx={1} name="envelope" size='20' />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <DialogButtonTitle>
                {t('member_reservation.reservation_invite.email')}
              </DialogButtonTitle>
              <DialogButtonSubtitle>
                {t('member_reservation.reservation_invite.email_description')}
              </DialogButtonSubtitle>
            </Box>
          </OwnerButtons>
          <OwnerButtons
            my={0.5}
            onClick={() => {
              setToastOpen(true);
              if (reservationInvite?.reservation?.share_reservation_url) copyToClipboard(reservationInvite?.reservation?.share_reservation_url);
            }}
          >
            <SvgIcon mx={1} name="chain_link" size='20' />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <DialogButtonTitle>
                {t('member_reservation.reservation_invite.copy_paste')}
              </DialogButtonTitle>
              <DialogButtonSubtitle>
                {t('member_reservation.reservation_invite.copy_paste_description')}
              </DialogButtonSubtitle>
            </Box>
          </OwnerButtons>
          <hr style={{ marginTop: '1rem', borderColor: 'primary.main' }} />
          <DialogButtonSubtitle mt={0.25} mb={2} color="primary.light">
            {t('member_reservation.reservation_invite.owner_dialog_reminder')}
          </DialogButtonSubtitle>
          <Toast
            type="success"
            text={t('member_reservation.reservation_invite.link_copied')}
            open={toastOpen}
            onClose={() => setToastOpen(false)}
          />
        </ModalContainer>
    },
    guest: {
      cardBadgeContent: t('member_reservation.reservation_invite.badge_label_guest'),
      modalHeaderContent: '',
      modalBodyContent:
        <ModalContainer>
          <Box
            display={!guestDialog ? 'none' : 'flex'}
            alignItems="center"
            flexDirection="column"
          >
            <SvgIcon name="blue_info" size="72" mb={1}/>
            <DialogGuestTitle>{t('member_reservation.reservation_invite.remove_invite_guest_title')}</DialogGuestTitle>
            <DialogGuestSubtitle pb={2.5}>
              {t('member_reservation.reservation_invite.remove_invite_guest_subtitle')}
            </DialogGuestSubtitle>
            <Box px={{ xs: 1, md: 0 }} display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
              <Button
                variant="text"
                mr={0.25}
                onClick={() => setOpenShareBookDialog()}
                sx={{
                  width: { xs: '100%', md: 'auto' }
                }}
              >
                {t('member_reservation.reservation_invite.remove_invite_button_no')}
              </Button>
              <Button
                onClick={() => removeInvitation()}
                ml={0.25}
                sx={{
                  width: { xs: '100%', md: 'auto' }
                }}
              >
                {t('member_reservation.reservation_invite.remove_invite_guest_button_yes')}
              </Button>
            </Box>
          </Box>
          <Box
            display={guestDialog ? 'none' : 'flex'}
            alignItems="center"
            flexDirection="column"
          >
            <SvgIcon name="success_circle_outline" size="72" />
            <Typography
              fontSize="1.125rem"
              fontWeight={600}
              lineHeight='24px'
              mt={1.5}
            >
              {t('member_reservation.reservation_invite.remove_invite_removed_title')}
            </Typography>
            <Typography
              fontSize="0.875rem"
              lineHeight='21px'
              textAlign="center"
            >
              {t('member_reservation.reservation_invite.remove_invite_removed_description')}
            </Typography>
            <Typography
              fontSize="0.875rem"
              textAlign="center"
              pb={1.5}
            >
              {t('member_reservation.reservation_invite.remove_invite_removed_invite_again')}
            </Typography>
          </Box>
        </ModalContainer>
    }
  };

  const DrawerOrDialog = () => {
    return mobile ? (
      <Drawer
        anchor="bottom"
        open={openShareBookDialog}
        onClose={() => setOpenShareBookDialog()}
        headerContent={
          reservationInviteOwnershipContent[reservationInvite?.ownership].modalHeaderContent
        }
      >
        {reservationInviteOwnershipContent[reservationInvite?.ownership].modalBodyContent}
      </Drawer>
    ) : (
      <Dialog
        titleAlign='flex-start'
        open={openShareBookDialog}
        className={''}
        onClose={() => setOpenShareBookDialog()}
        headerContent={
          reservationInviteOwnershipContent[reservationInvite?.ownership].modalHeaderContent
        }
        sx={{
          width: 1,
          '& .MuiDialog-paper': { backgroundColor: reservationInvite?.ownership === 'owner' ? 'background.100' : 'secondary.main' },
          '& .MuiDialogContent-root': { backgroundColor: 'secondary.main' }
        }}
      >
        {reservationInviteOwnershipContent[reservationInvite?.ownership].modalBodyContent}
      </Dialog>
    );
  };

  const SwiperControls = () => (
    <SwiperControlsContainer>
      <LeftArrow onClick={e => slideTo(e, 'slidePrev')} />
      <RightArrow onClick={e => slideTo(e, 'slideNext')} />
    </SwiperControlsContainer>
  );

  const propertyLink = (seasonalLink) ? (() => {
    switch (season) {
    case 'black_friday':
      return `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('black_friday_page.whatsapp_message_2', { apartment: property.internal_id })}`;
    case 'new_year':
      return `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('new_year_page.whatsapp_message_2', { apartment: property.internal_id })}`;
    case 'carnival_rj':
      return `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('carnival_rj.whatsapp_message_2', { apartment: property.internal_id })}`;
    case 'carnival_sp':
      return `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('carnival_sp.whatsapp_message_2', { apartment: property.internal_id })}`;
    default:
      return property.link;
    }
  })() : (
    property.link
  );

  return (
    <ThemeProvider>
      <Link
        href={casatb ? `${t('campaign_tracker.building.whatsapp_link_message', {
          apartment_id: property.internal_id,
          building_name: property.building_name
        })}` : propertyLink}
        target={!isWebview && '_blank'}
        noDecoration
        sx={{ flex: 1, width: 1, height: 1 }}
      >
        <Card casatb={casatb} sx={{ borderRadius: casatb ? '8px' : '4px' }} id={property.id} ref={cardRef} className="property_card" rounded={rounded} $withbody={withBody || seasonalLink}>
          <CardImageContainer sx={{ height: mapVersion ? 220 : 270 }}>
            {property.photos?.length && (
              <>
                {swiperActive ? (
                  <Swiper
                    slides={property.photos.map((photo, index) => (
                      <CardImage key={index} src={inView ? photo : undefined} />
                    ))}
                    styles={{
                      pagination: cardPagination,
                      bullet: cardPaginationBullet,
                      activeBullet: cardPaginationBulletActive
                    }}
                    onSwiper={setSwiper}
                    spaceBetween={0}
                  />
                ) : (
                  <CardImage src={mapVersion || inView ? property.photos[0] : undefined} />
                )}
              </>
            )}
            {property.internal_id && (
              <CardIdBadge casatb={casatb} $idBadgeLocation={idBadgeLocation} label={
                <CardIdText sx={{ typography: mapVersion ? 'caption' : 'lead' }}>
                  {`ID ${property.internal_id}`}
                </CardIdText>
              }
              />
            )}
            {property.is_accessible_bathroom && (
              <SvgIcon
                name="filled_wheelchair"
                sx={{
                  position: 'absolute',
                  bottom: '0.5rem',
                  left: '0.5rem',
                  zIndex: 'tooltip'
                }}
              />
            )}
            {withReservationInvite && <CardIdBadge
              $idBadgeLocation={'top'}
              $idBadgeLocationX={'right'}
              onClick={function(e) {
                e.preventDefault();
                e.stopPropagation();
                setOpenShareBookDialog();
              }}
              clickable={false}
              sx={{ zIndex: 'tooltip', cursor: 'pointer' }}
              label={
                <CardIdText sx={{ typography: mapVersion ? 'caption' : 'lead' }}>
                  {reservationInviteOwnershipContent[reservationInvite?.ownership].cardBadgeContent}
                </CardIdText>
              } />
            }
            {withReservationInvite && DrawerOrDialog()}
            {withReservationInvite && (
              <Toast
                type="error"
                text={t('member_reservation.reservation_invite.failed_to_deny_invite')}
                width="30%"
                open={errorToastOpen}
                onClose={() => {
                  setErrorToastOpen(false);
                }}
              />
            )}
            {property.expected && (
              <ComingSoonContainer>
                <ComingSoonContent>
                  <Typography variant="h4" lineHeight="2.25rem">
                    {t('coming_soon_msg.title')}
                  </Typography>
                  <Typography
                    component="p"
                    fontSize="0.625rem"
                    lineHeight="15px"
                    fontWeight="400"
                    paddingRight="1rem"
                    sx={{ opacity: '0.8' }}
                  >
                    {t('coming_soon_msg.description')}
                  </Typography>
                  <Typography component="p" variant="h5" lineHeight="1.875rem" fontWeight="500">
                    {t('coming_soon_msg.bottom')}
                  </Typography>
                </ComingSoonContent>
              </ComingSoonContainer>
            )}
            {swiperActive && <SwiperControls />}
          </CardImageContainer>
          {withBody ? (
            <CardInfoContainer>
              <Box>
                <Typography variant="lead" sx={{ fontWeight: 400, color: casatb ? '#606F7D' : 'primary.main' }}>
                  {!!property.building_name && `${property.building_name} • `}
                  {property.neighbourhood.name}
                </Typography>
                <Typography variant="h6" component="p" sx={{ mb: casatb ? '1rem' : 0 }}>
                  {`${property.listing_name}`}
                </Typography>
                <Typography variant="caption" sx={{ fontWeight: 500, color: casatb ? '#606F7D' : 'primary.main' }}>
                  {property.home_type === 'Duplex' && (
                    `${property.home_type} • `
                  )}
                  {`${property.bedrooms_label} •
                  ${property.bathrooms_label}
                  ${property.is_cleaning_service ? `• ${t('property_card.daily_cleaning')}` : ''}`}
                </Typography>
              </Box>
              {(!!property.min_price_dates && !!property.min_price && !availableOnRequest && !casatb) && (
                <Link
                  href={property.link_deal}
                  target={!isWebview && '_blank'}
                  noDecoration
                >
                  <BestDealContainer>
                    <SvgIcon name="best_deal" />
                    <Box>
                      <BestDealTitle>
                        {property.min_price >= property.price ? t('property_card.suggestion') : t('property_card.best_deal')}
                      </BestDealTitle>
                      <BestDealContent>
                        {property.min_price_dates}
                      </BestDealContent>
                    </Box>
                    <BestDealPrice>
                      {property.min_price_label.price}
                      <Typography variant="caption" fontSize="0.625rem">
                        {property.min_price_label.suffix}
                      </Typography>
                    </BestDealPrice>
                  </BestDealContainer>
                </Link>
              )}
              <CardPriceInfoContainer sx={{ pt: mapVersion ? 0.5 : 1.5 }}>
                <Box sx={{ flex: 1 }}>
                  {property.price_promotional && !property.price_for_less_than_30_days && (
                    <Typography variant="lead" sx={{ textDecoration: 'line-through' }}>
                      {property.original_price_label}
                    </Typography>
                  )}
                  {property.price_for_less_than_30_days && (
                    <Typography variant="lead" mb={1}>
                      {t('property_card.price_for_selected_dates')}
                    </Typography>
                  )}
                  <Typography variant="h5" sx={{ fontWeight: 500 }} component="div">
                    {property.price_for_less_than_30_days
                      ? property.price_for_less_than_30_days
                      : property.price_initial && !property.price_precise && property.neighbourhood.city_id === 2
                        ? property.price_initial_label
                        : property.price_label}
                    <Typography variant="caption">
                      {!property.price_for_less_than_30_days && t('property_card.by_month')}
                    </Typography>
                    <Typography variant="caption" paragraph sx={{ fontWeight: 500, marginBottom: 0 }}>
                      {property.listing_subname}
                    </Typography>
                  </Typography>
                </Box>
                <Box sx={{ ...(availableOnRequest && { 'max-width': '45%', width: 'fit-content' }) }}>
                  <Box
                    padding='2px 8px'
                    backgroundColor='primary.10'
                    borderRadius='3px'
                    sx={{ textAlign: 'center' }}
                  >
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      {availableOnRequest ? t('property_card.available_on_request_badge') : t('property_card.available_badge')}
                    </Typography>
                    {!availableOnRequest &&
                      (
                        <Typography variant="caption" sx={{ fontWeight: 300 }}>
                          {` ${property.next_available_day_label}`}
                        </Typography>
                      )
                    }
                  </Box>
                </Box>
              </CardPriceInfoContainer>
              {!mapVersion && property.foo && (
                <Typography variant="caption" mt={1}>
                  {property.foo}
                </Typography>
              )}
            </CardInfoContainer>
          ) : seasonalLink ? (
            <CardInfoContainer>
              <Box>
                <Typography variant="lead" sx={{ fontWeight: 400 }}>
                  {!!property.building_name && `${property.building_name} • `}
                  {property.neighbourhood.name}
                </Typography>
                <Typography variant="h6" component="p">
                  {`${property.listing_name}`}
                </Typography>
                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                  {property.home_type === 'Duplex' && (
                    `${property.home_type} • `
                  )}
                  {`${property.bedrooms_label} •
                  ${property.bathrooms_label}
                  ${property.is_cleaning_service ? `• ${t('property_card.daily_cleaning')}` : ''}`}
                </Typography>
              </Box>
              {(() => {
                switch (season) {
                case 'black_friday':
                  return (
                    <>
                      <BestDealContainer>
                        <SvgIcon name="best_deal" />
                        <Box>
                          <BestDealTitle>
                            {t('black_friday_page.apartments_section.special_price')}
                          </BestDealTitle>
                          <BestDealContent>
                            {t('black_friday_page.apartments_section.special_price_2')}
                          </BestDealContent>
                        </Box>
                        <BestDealPrice>
                          <Box>
                            <BestDealTitle>
                              {' R$'}
                              {customPrice}
                              {t('property_card.by_month')}
                            </BestDealTitle>
                            <BestDealContent>
                              {' ('}
                              <Typography variant="captionsmall" sx={{ textDecoration: 'line-through' }}>
                                {customOriginalPrice}
                              </Typography>
                              {t('property_card.by_month')}
                              {') \n'}
                            </BestDealContent>
                          </Box>
                        </BestDealPrice>
                      </BestDealContainer>
                      <Button size="large" sx={{ mt: 1 }}>
                        <SvgIcon name="whatsapp_light" size={20} mr={0.25} />
                        {t('new_year_page.whatsapp_us')}
                      </Button>
                      <Button
                        size="large"
                        color="secondary"
                        target="_blank"
                        border={true}
                        href={property.city_link}
                        sx={{ mt: 1 }}
                      >
                        {t('new_year_page.button')}
                      </Button>
                    </>
                  );
                case 'new_year':
                  return (
                    <>
                      <Button size="large" target="_blank" sx={{ mt: 1 }}>
                        <SvgIcon name="whatsapp_light" size={20} mr={0.25} />
                        {t('new_year_page.whatsapp_us')}
                      </Button>
                      <Button
                        size="large"
                        color="secondary"
                        target="_blank"
                        border={true}
                        href={`${property.city_link}?search[check_in]=2023-12-28&search[check_out]=2024-01-02`}
                        sx={{ mt: 1 }}
                      >
                        {t('new_year_page.button')}
                      </Button>
                    </>
                  );
                case 'carnival_sp':
                  return (
                    <>
                      <Button size="large" target="_blank" sx={{ mt: 1 }}>
                        <SvgIcon name="whatsapp_light" size={20} mr={0.25} />
                        {t('carnival_sp.whatsapp_us')}
                      </Button>
                      <Button
                        size="large"
                        color="secondary"
                        target="_blank"
                        border={true}
                        href={`${property.city_link}?search[check_in]=2024-02-08&search[check_out]=2024-02-14`}
                        sx={{ mt: 1 }}
                      >
                        {t('carnival_sp.button')}
                      </Button>
                    </>
                  );
                case 'carnival_rj':
                  return (
                    <>
                      <Button size="large" target="_blank" sx={{ mt: 1 }}>
                        <SvgIcon name="whatsapp_light" size={20} mr={0.25} />
                        {t('carnival_rj.whatsapp_us')}
                      </Button>
                      <Button
                        size="large"
                        color="secondary"
                        target="_blank"
                        border={true}
                        href={`${property.city_link}?search[check_in]=2024-02-08&search[check_out]=2024-02-14`}
                        sx={{ mt: 1 }}
                      >
                        {t('carnival_rj.button')}
                      </Button>
                    </>
                  );
                default:
                  return null;
                }
              })()}
            </CardInfoContainer>
          ) : (
            <Box display="flex" justifyContent="center">
              <ApartmentContentWrapper>
                <ApartmentNeighbourhood>
                  {!!property.city?.name && `${property.city.name} • `}
                  {property.neighbourhood.name}
                </ApartmentNeighbourhood>
                <ApartmentStreet>{property.listing_name}</ApartmentStreet>
                <Box
                  display="flex"
                  flexDirection={mobile ? 'column' : 'row'}
                  sx={{ width: '100%', justifyContent: 'space-between', padding: mobile ? '0 0.5rem' : '0' }}
                >
                  <ApartmentDetail>
                    Ap {property.number} - {property.floor_number_text}{t('bookings_page.floor')}
                  </ApartmentDetail>
                  {reservationInvite?.ownership === 'guest' && <ApartmentDetail sx={{ marginTop: mobile ? '0.25rem' : '0' }}>
                    {`${t('invitations_page.invited_by')} ${reservationInvite?.owner_name}`}
                  </ApartmentDetail>}
                </Box>
              </ApartmentContentWrapper>
            </Box>
          )}
        </Card>
      </Link>
      {!mapVersion && divider && (
        <Divider sx={{ display: ['block', 'none'], color: 'primary.20' }} />
      )}
    </ThemeProvider>
  );
};

export default PropertyCard;
