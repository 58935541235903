import React, { useState } from 'react';
import { Container, Box, Typography, HtmlTranslation, SvgIcon, Link } from '../elements';
import { Swiper, Tabs } from '.';
import PropertyCard from './PropertyCard';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { t } from '../../../js/common/translations';
import placeholderHero from '../../../images/building_images/placeholder_hero.png';

import {
  Description,
  Title,
  Subtitle,
  CityBuildingContainer,
  ImageWrapper,
  BuildingName,
  CityNeighbourhoodName,
  BuildingDescription,
  AboutBuildingWrapper,
  BuildingImage,
  Section,
  PropertiesBox,
  TopTextContainer
} from './BuildingSection.styles';
import { ThemeProvider } from '../../contexts/theme';

const BuildingSection = ({
  buildings,
  showTab = true,
  casatb
}) => {
  const currBreakpoint = useBreakpoint();
  const [currentBuildingTab, setCurrentBuildingTab] = useState(0);
  const showPropertiesSliderMobile = ['xs'].includes(currBreakpoint);

  const BuildingHeroImage = (photos = []) => {
    const photosByPlace = photos.photos.reduce((
      places,
      // eslint-disable-next-line camelcase
      { img_alt, url_for_photo_image_variant_r2f_711x475: url }
    ) => ({
      ...places,
      [img_alt.toLowerCase().replace(/\s/g, '_')]: url
    }), {});

    return (
      <ImageWrapper casatb sx={{ width: { xs: '100%', sm: '50%', lg: 'auto' } }} >
        {/* eslint-disable camelcase */}
        <Box component='picture' src={photosByPlace?.building_hero || placeholderHero} height={{ xs: 255, md: 372 }} width={{ xs: '100%', lg: 502 }} sx={{ objectFit: 'cover' }}>
          <BuildingImage src={photosByPlace?.building_hero || placeholderHero} height={{ xs: 255, md: 372 }} width={{ xs: '100%', lg: 502 }} />
        </Box>
        {/* eslint-enable camelcase */}
      </ImageWrapper >
    );
  };

  return (
    <ThemeProvider>
      <Section>
        <Container>
          {!casatb &&
            <TopTextContainer mb={2}>
              <Subtitle>{t('buildings_home.subtitle')}</Subtitle>
              <Title>
                <HtmlTranslation text={t('buildings_home.title_html')} />
              </Title>
              <Description display={{ xs: 'none', md: 'flex' }}>
                {t('buildings_home.description')}
              </Description>
            </TopTextContainer>
          }
          {!!buildings.length && (
            <Box>
              <Tabs scroll data={buildings.map(item => ({
                label: item.name,
                content: (
                  <>
                    <CityBuildingContainer>
                      <BuildingHeroImage photos={item.photos} casatb={casatb} />
                      <AboutBuildingWrapper>
                        <CityNeighbourhoodName casatb={casatb}>{item.neighbourhood.name} • {item.city.name}</CityNeighbourhoodName>
                        <BuildingName casatb={casatb}>{item.name}</BuildingName>
                        <BuildingDescription casatb={casatb} sx={{ whiteSpace: 'break-spaces' }}>
                          {item.home_page_description}
                        </BuildingDescription>
                        <Link href={item.path} display={casatb ? 'none' : 'flex'} mt={1} alignItems='center'>
                          <Typography mr={0.5}>
                            {t('buildings_home.magdalena_laura.read_more')}
                          </Typography>
                          <SvgIcon name="control_chevron_right" width={10} height={10} />
                        </Link>
                      </AboutBuildingWrapper>
                    </CityBuildingContainer>
                    {!!item.first_three_properties && !!item.first_three_properties?.length && (
                      <>
                        <Box sx={{ mb: '1rem', display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography color='primary.main'>{t('buildings_home.stay_at')} {item.name}</Typography>
                        </Box>
                        {showPropertiesSliderMobile ? (
                          <Box>
                            <Swiper
                              style={{ paddingBottom: '2rem' }}
                              slides={item.first_three_properties.map((item, i) => (
                                <Box sx={{ mx: 0, width: '100%', height: '100%' }} key={i}>
                                  <PropertyCard casatb={casatb} property={item} availableOnRequest={item.availability_on_request} />
                                </Box>
                              ))}
                              loop={false}
                              spaceBetween={10}
                            />
                          </Box>
                        ) : (
                          <PropertiesBox justifyContent={item.first_three_properties.length < 3 ? 'space-around' : 'flex-start'}>
                            {item.first_three_properties.map((item, i) => (
                              <Box sx={{ mx: 0.5, minWidth: 350, maxWidth: item.first_three_properties?.length === 1 ? '100%' : 350 }} key={i}>
                                <PropertyCard casatb={casatb} property={item} availableOnRequest={item.availability_on_request} />
                              </Box>
                            ))}
                          </PropertiesBox>
                        )}
                      </>
                    )}
                  </>
                )
              }))}
              showTab={showTab}
              currentTab={currentBuildingTab}
              onTabClick={(tabIndex) => setCurrentBuildingTab(tabIndex)}
              />
            </Box>
          )}
        </Container>
      </Section>
    </ThemeProvider >
  );
};

export default BuildingSection;
