import styled from 'styled-components';
import { Box, Typography } from '../elements';

export const TopTextContainer = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const Subtitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.light};
  text-transform: uppercase;
`;

export const Title = styled(Typography)`
  font-family: 'Cambon';
  font-size: 2.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 1.167;
`;

export const Description = styled(Typography).attrs({
  py: 1
})`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.primary.light};
  font-family: 'Poppins';
  text-align: center;
  width: 60%;
`;

export const CityBuildingContainer = styled(Box).attrs({
  py: 2
})`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    justify-content: space-around;
    margin-bottom: 2rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled(Box)`
  border-radius: 0.5rem;
  overflow: hidden;
  border-bottom-right-radius: 80px;
  border-top-left-radius: ${({ casatb }) => casatb ? '80px' : '0.5rem'};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export const BuildingName = styled(Typography)`
  font-size:  ${({ casatb }) => casatb ? '40px' : '30px'};
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ casatb }) => casatb ? 'LexendExa' : 'Poppins'};
  line-height: 45px;
  font-weight: ${({ casatb }) => casatb ? '300' : '600'};
  margin-top:  ${({ casatb }) => casatb ? '0.5rem' : '0'};
  margin-bottom:  ${({ casatb }) => casatb ? '1.5rem' : '0'};
`;

export const CityNeighbourhoodName = styled(Typography)`
  font-weight: 400;
  font-size:  ${({ casatb }) => casatb ? '18px' : '14px'};
  font-family: ${({ casatb }) => casatb ? 'Inter' : 'Poppins'};
  line-height: 21px;
  color: #686a70;
  text-transform: ${({ casatb }) => casatb ? 'capitalize' : 'uppercase'};
`;

export const BuildingDescription = styled(Typography)`
  font-size: ${({ casatb }) => casatb ? '18px' : '1.25rem'};
  color: ${({ theme }) => theme.palette.primary.light};
  font-family: ${({ casatb }) => casatb ? 'Inter' : 'Poppins'};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
    width: 100%;
  }
`;

export const AboutBuildingWrapper = styled(Box).attrs({
  px: 2
})`
  max-width: 50%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
  }
`;

export const BuildingImage = styled(Box).attrs({
  component: 'img'
})`

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  object-fit: cover;
`;

export const Section = styled(Box).attrs({
  py: 3
})`
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const PropertiesBox = styled(Box)`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 1rem;

  ${({ theme }) => theme.breakpoints.up('lg')}{
    overflow-x: hidden;
  }

  &::-webkit-scrollbar{
    width: 10px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb{
    background: #D3D4D4;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
  }
  &::-webkit-scrollbar-track{
    background: #F0F0F0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }
`;
