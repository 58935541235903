import React, { useState, useLayoutEffect } from 'react';
import {
  AppBar,
  Toolbar,
  SvgIcon,
  Typography,
  Box
} from '../elements';
import { DropdownMenu, SearchField, MobileDialogMenu } from '../modules';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useScroll } from '../../hooks/useScroll';

import {
  CityButtonText,
  HamburguerBtn,
  HeaderActionsContainer,
  HeaderItemIcon,
  HeaderLink,
  TabasLogo,
  HeaderTitleContainer,
  LinkIcon,
  LinksContainer,
  MemberMenuButton,
  SearchNeighborhoodContainer,
  SupportMenuItem,
  SupportMenuItemDescription,
  SupportMenuItemLabel,
  ToolbarContainer
} from './Header.styles';

const Header = ({
  links,
  citySelectorDropdown,
  currentMember,
  currentMemberIsAdmin,
  toolbarProps = {},
  title,
  backBtnUrl,
  searchAreaActive,
  startTransparent,
  topInfoBanner,
  casatb,
  ...rest
}) => {
  const currBreakpoint = useBreakpoint();
  const scrollState = useScroll();
  const [barStyle, setBarStyle] = useState(startTransparent ? 'transparent' : 'filled');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobile = ['xs', 'sm'].includes(currBreakpoint);
  const hiddenByScroll = scrollState.direction === 'down' && scrollState.value > 80;
  const searchAreaVisible = searchAreaActive && window.scrollY > window.innerHeight * 0.55;

  useLayoutEffect(() => {
    const handleTransparency = () => {
      setBarStyle(window.scrollY < window.innerHeight * 0.05 ? 'transparent' : 'filled');
    };

    if (startTransparent) {
      window.addEventListener('scroll', handleTransparency);
      handleTransparency();
    }

    return () => {
      window.removeEventListener('scroll', handleTransparency);
    };
  }, []);

  const barStyles = {
    filled: {
      color: 'primary',
      logo: 'logo_default',
      login_icon: 'logged_user',
      language_icon: 'globe',
      arrow_down: 'arrow_down'
    },
    transparent: {
      background: 'transparent',
      color: 'secondary',
      logo: 'logo_white',
      login_icon: 'logged_user_white',
      language_icon: 'globe_white',
      arrow_down: 'arrow_down_white'
    }
  };

  const locales = [
    {
      href: t('navigation.locale_en'),
      description: 'ENGLISH'
    },
    {
      href: t('navigation.locale_pt'),
      description: 'PORTUGUÊS'
    }
  ];

  return (
    <ThemeProvider>
      <AppBar
        background={barStyles[barStyle]?.background}
        disableShadow={barStyle === 'transparent'}
        retracted={isMobile && hiddenByScroll}
        sx={{ maxHeight: 56, top: topInfoBanner ? [64, 32] : 0 }}
        {...rest}
      >
        <Toolbar {...toolbarProps} component="section" className="navbar">
          <ToolbarContainer disableGutters={isMobile}>
            {backBtnUrl && isMobile ? (
              <LinkIcon href={backBtnUrl} sx={{ display: ['none', 'none', 'block'] }}>
                <SvgIcon name="arrow_left" size={15} />
              </LinkIcon>
            ) : (
              <HeaderLogo
                small={isMobile && (title || searchAreaVisible || citySelectorDropdown)}
                barStyles={barStyles[barStyle]}
                casatb={casatb}
              />
            )}
            {!searchAreaVisible && !citySelectorDropdown && (
              <HeaderContent text={title} hideLogo={!backBtnUrl} />
            )}
            {searchAreaVisible && (
              <SearchNeighborhoodContainer>
                <SearchField />
              </SearchNeighborhoodContainer>
            )}
            {citySelectorDropdown && (
              <CitiesSelector city={citySelectorDropdown} barStyles={barStyles[barStyle]} />
            )}
            {(links && !searchAreaVisible && !citySelectorDropdown && !casatb) && (
              <Box
                component="nav"
                className="firstNav"
                width={1}
                sx={(isMobile && { display: 'none' })}
              >
                <LinksContainer
                  component="ul"
                  itemScope
                  itemType="https://schema.org/SiteNavigationElement"
                  role="menu"
                >
                  {links.map(({ href, description, active, cityModal, ...linkProps }, index) => (
                    <Box
                      component="li"
                      itemProp="name"
                      role="menuitem"
                      key={index}>
                      {cityModal ? (
                        <HeaderLink
                          itemProp="url"
                          data-bs-toggle="modal"
                          data-bs-target="#modalCity"
                          id="city_selector"
                          href={href}
                          $active={active}
                          color={barStyles[barStyle].color}
                          {...linkProps}
                        >
                          {description}
                        </HeaderLink>
                      ) : (
                        <HeaderLink
                          itemProp="url"
                          href={href}
                          $active={active}
                          color={barStyles[barStyle].color}
                          {...linkProps}
                        >
                          {description}
                        </HeaderLink>
                      )}
                    </Box>
                  ))}
                </LinksContainer>
              </Box>
            )}
            <HeaderActions
              locales={locales}
              barStyles={barStyles[barStyle]}
              currentMember={currentMember}
              currentMemberIsAdmin={currentMemberIsAdmin}
              casatb={casatb}
            />
          </ToolbarContainer>
          <HamburguerBtn id="hamburguer-menu-btn" onClick={() => setMobileMenuOpen(true)} sx={{ display: casatb ? 'none' : 'inline-flex' }} />
        </Toolbar>
      </AppBar>
      {!startTransparent && (
        <Toolbar />
      )}
      <MobileDialogMenu
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        links={links}
        currentMember={currentMember}
        currentMemberIsAdmin={currentMemberIsAdmin}
      />
    </ThemeProvider>
  );
};

const HeaderLogo = ({ small, barStyles, casatb }) => (
  <LinkIcon href="/">
    {casatb ? <SvgIcon size="100" name='casatb_default_logo' alt={t('casatb.alt_logo_dark')} />
      : (
        small ? (
          <TabasLogo size="40" name="logo_sm_bg_dark" alt={t('all_alt.black_logo_tabas')} />
        ) : (
          <TabasLogo size="130" name={barStyles.logo} alt={barStyles.color === 'primary' ? t('all_alt.black_logo_tabas') : t('all_alt.white_logo_tabas')} />
        )
      )}
    { }
  </LinkIcon>
);

const HeaderContent = ({ text, hideLogo }) => (
  <HeaderTitleContainer>
    {!hideLogo && (
      <LinkIcon href="/">
        <TabasLogo size={text ? 80 : 105} name="logo_default" />
      </LinkIcon>
    )}
    {text && (
      <Typography
        sx={{ color: 'primary.light', py: 0, width: 'max-content' }}
        mt={hideLogo ? 0 : 0.3}
        variant={hideLogo ? 'body1' : 'caption'}
        component="caption"
      >
        {text}
      </Typography>
    )}
  </HeaderTitleContainer>
);

const CitiesSelector = ({ city, barStyles }) => (
  <HeaderLink
    sx={{ mx: 'auto' }}
    data-bs-toggle="modal"
    data-bs-target="#modalCity"
    id="city_selector"
  >
    <Typography
      textTransform="capitalize"
      color={barStyles.color}
      sx={{ display: 'flex', flexDirection: 'row' }}
      id="search_city_selector"
    > Explore
      {<span>&nbsp;</span>}
      <CityButtonText
        id="city_selected_id"
        color={barStyles.color}
      >
        • {city}
      </CityButtonText>
      {<span>&nbsp;</span>}
      <SvgIcon name={barStyles.arrow_down} />
    </Typography>
  </HeaderLink>
);

const HeaderActions = ({ locales, barStyles, currentMember, currentMemberIsAdmin, casatb }) => {
  const supportItems = [
    {
      icon_props: { name: 'faq', size: 18 },
      label: t('navigation.support.faq'),
      href: t('navigation.support.faq_path'),
      description: t('navigation.support.faq_text'),
      casatb: casatb
    },
    {
      icon_props: { name: 'whatsapp', size: 18 },
      label: t('navigation.support.whatsapp'),
      className: 'whatsapp-link whatsapp-link-menu',
      rel: 'nofollow',
      description: t('navigation.support.whatsapp_contact_formatted'),
      target: '_blank',
      href: `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.support')}`
    },
    {
      icon_props: { name: 'phone', size: 18 },
      label: t('navigation.support.phone'),
      href: t('navigation.support.contact_phone'),
      description: t('navigation.support.contact_phone_formatted')
    },
    {
      icon_props: { name: 'envelope', size: 18 },
      label: t('navigation.support.email'),
      href: t('navigation.support.email_send'),
      description: t('contact.hello_email')
    }
  ];

  const authLinks = [
    {
      href: t('navigation.new_member_registration_path'),
      description: t('navigation.sign_up_link')
    },
    {
      icon_props: { name: barStyles.login_icon, size: 18 },
      href: t('navigation.new_session_path'),
      description: t('navigation.login_link')
    }
  ];

  return (
    <HeaderActionsContainer casatb={casatb}>
      {locales && (
        <DropdownMenu
          anchorEl={HeaderLink}
          anchorElProps={{
            id: 'language',
            children: <SvgIcon name={barStyles.language_icon} size="20" />,
            color: barStyles.color
          }}
          menuProps={{
            sx: {
              mt: 1
            }
          }}
          menuItems={locales}
          menuItemProps={{
            divider: true
          }}
        />
      )}
      <DropdownMenu
        anchorEl={HeaderLink}
        anchorElProps={{
          children: t('navigation.support'),
          className: 'support-bt-menu',
          color: barStyles.color
        }}
        menuProps={{
          className: 'support-menu-modal',
          sx: {
            mt: 1
          }
        }}
        menuItems={supportItems}
        menuItemProps={{
          divider: true
        }}
        menuItemRender={(item, { key, ...rest }) => (
          <span key={key} style={{ display: item.casatb ? 'none' : 'inline-block' }}>
            <SupportMenuItemLabel>
              {item.label}
            </SupportMenuItemLabel>
            <SupportMenuItem {...rest}>
              <SupportMenuItemDescription>
                <HeaderItemIcon {...item.icon_props} />
                {item.description}
              </SupportMenuItemDescription>
            </SupportMenuItem>
          </span>
        )}
      />
      {!casatb && (
        currentMember
          ? (
            <HeaderUserDropdown
              currentMember={currentMember}
              currentMemberIsAdmin={currentMemberIsAdmin}
              barStyle={barStyles}
            />
          )
          : authLinks.map(({
            href,
            description,
            active,
            icon_props: iconProps
          }, index) => (
            <HeaderLink
              key={index}
              href={href}
              $active={active}
              color={barStyles.color}
            >
              {iconProps && (
                <HeaderItemIcon {...iconProps} />
              )}
              {description}
            </HeaderLink>
          ))
      )}
    </HeaderActionsContainer>
  );
};

const HeaderUserDropdown = ({ currentMember, currentMemberIsAdmin, barStyle }) => {
  const adminItems = [
    {
      href: t('logged_user_navigation.backoffice_path'),
      description: 'Backoffice',
      iconEl: <SvgIcon name="desktop" size="18" />
    }
  ];

  const memberItems = [
    {
      href: t('logged_user_navigation.bookings_path'),
      description: t('logged_user_navigation.bookings'),
      iconEl: <SvgIcon name="user" size="18" />
    },
    {
      href: t('logged_user_navigation.destroy_session'),
      description: 'Log Out',
      iconEl: <SvgIcon name="logout" size="18" />
    }
  ];

  return (
    <DropdownMenu
      anchorEl={MemberMenuButton}
      anchorElProps={{
        children: (
          <Typography>
            {currentMember.fullname?.toUpperCase()}
          </Typography>
        ),
        color: barStyle.color
      }}
      menuProps={{
        sx: {
          mt: 1,
          a: {
            minWidth: 200
          }
        }
      }}
      menuItems={
        currentMemberIsAdmin
          ? [...adminItems, ...memberItems]
          : memberItems
      }
      menuItemProps={{
        divider: true
      }}
    />
  );
};

export default Header;
